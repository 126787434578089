.energy-table {
  margin: 0 auto;
  max-width: 1500px;
  overflow: auto;
  text-align: center;
  margin-left: 22px;
}

.energy-cost-result {
  margin: 0 auto;
}

.energy-cost-result tr {
  border-bottom: 2px;
}

.el-items input {
  margin: 0;
  padding: 0;
  width: 45px;
}

.energy-cost-result tr td.el-items {
  background-color: #FED130;
}

.energy-cost-result th, .energy-cost-result td {
  padding: 20px;
  background-color: white;
}

@media screen and (max-device-width: 768px) {
  .energy-cost-result th, .energy-cost-result td {
      padding: 5px;
  }
}
.calculator-span-errors {
  color: red;
  font-style: normal;
  font-variant: normal;
  font-size: 12px;
  float: left;
  text-align: left;
}

.hidden {
  display: none;
}

.app-header {
  background-image: url("../../img/header-background.png");
  max-width: 100%;
  overflow: auto;
  padding: 0 0 50px 0;
  background-size: cover;
}

#logo {
  padding-bottom: 25px;
  align-self: center;
  padding-right: 50px;
  width: 200px;
}

#sunny-logo {
  padding-left: 50px;
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.calculator-wrapper {
  background-color: #f0f0f0;
  width: 87%;
  margin: 80px auto 0 auto;
  padding-bottom: 50px;
  border-radius: 5px;
}

.calculator-arrow {
  max-width: 699px;
  min-height: 139px;
  background: url("../../img/arrow.png") no-repeat;
  position: relative;
  top: -49px;
  left: -34px;
}

.calculator-arrow p {
  font-size: 30px;
  color: white;
  max-width: 609px;
  text-align: left;
  margin: 0 auto;
}

.calculator-arrow-mobile {
  display: none;
}

@media screen and (max-device-width: 768px) {
  .calculator-wrapper {
      background-color: #f0f0f0;
      width: 85%;
      margin: 50px auto 0 auto;
      padding-bottom: 50px;
      border-radius: 5px;
  }

  .calculator-arrow {
    display: none;
  }

  .calculator-arrow-mobile {
    display: block;
    background-color: #286AB0;
    width: 85%;
    border-radius: 10px;
    margin: 50px auto 0 auto;
  }
  
  .logo-wrapper {
    width: 85%;
    padding-left: 7.5%;
    flex-direction: column;
    justify-content: center;
  }

  #logo {
    padding: 0 0 30px 0;
  }

  #sunny-logo {
    padding: 0;
  }

  .calculator-arrow-mobile p b {
    font-size: 30px;
    margin: 0 auto;
    padding: 20px;
  }

  .calculator-arrow-mobile p {
      color: white;
      text-align: center;
      font-size: 20px;
  }
}
.app-middle {
  width: 80%;
  padding: 30px 0 30px 0;
  margin: 0 auto;
}

.middle-title {
  font-size: 40px;
  color: #4f4f4f;
  width: 100%;
  margin: 0 auto;
}

.images-block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.images-block p {
  color: #8a8a8a;
  font-size: 15px;
}

.image-block {
  width: 230px;
  margin: auto;
}

@media screen and (max-device-width: 768px) {
  .images-block {
      display: block;
  }

  .image-block {
    width: 100%;
  }
}
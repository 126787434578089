#calculator-step-one {
  background-color: #f0f0f0;
  width: 87%;
  min-height: 523px;
  margin: 80px auto 0 auto;
  padding-bottom: 50px;
  border-radius: 5px;
}

#result-table {
  margin-top: 20px;
}

.result-chart {
  width: 500px !important;
  height: 300px !important;
  display: inline-block;
}

.result-actions {
  text-align: left;
  margin-top: 10px;
}

.back-button {
  margin-left: 22px;
}

#emailMessage {
  text-align: center;
  font-size: 16px;
  padding: 5px 0;
}

#chart-title {
  max-width: 300px;
  height: 200px;
  position: absolute;
  display: inline-block;
}

#chart-title p {
  color: #434343;
  font-size: 20px;
  text-align: left;
  position: absolute;
  min-width: 210px;
  top: 60px;
}

.table-text {
  display: inline-block;
  color: white;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  margin-top: 10px;
  font-size: 14px;
  width: 50%;
}

.results-info {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
}

.peak-data {
  width: 100%;
  margin-bottom: 20px;
}

.peak-data-text {
  text-align: center !important;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-size: 14;
  text-align: center;
  width: 100%;
  display: inline-block;
}
.grid-info {
  display: inline-block;
  min-height: 180px;
}

.grid-info td {
  padding: 5px;
}

.grid-info td, input {
  font-size: 16px;
  color: #4d4d4d;
}

.grid-title {
  margin-bottom: 5px;
}

.grid-info p.grid-title {
  color: #434343;
  font-size: 20px;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.contact-info {
  text-align: left;
  padding: 10px;
  background-color: #286ab0;
  border-radius: 10px;
  height: 60%;
}

.contact-info label {
  min-width: 80px;
  color: white;
  font-size: 14px;
}

.contact-info p {
  font-size: 20px;
  color: white;
}

.contact-info input {
  border: none;
  background-color: white;
  color: #286ab0;
}

.multipliers {
  width: 10%;
  display: inline-block;
}

.multipliers-input {
  background-color: #FED130;
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
}

.multipliers input {
  width: 50px;
  margin-top: 10px;
  border: none;
  border-radius: 20px;
  text-align: center;
  margin-right: 10px;
  margin-left: 15px;
}

.multipliers input.sec {
  margin-top: 25px;
}

.contact-field {
  display: flex;
  margin-bottom: 5px;
}


.next-button p {
  font-size: 18px;
  padding: 10px 30px 10px 30px;
}

.calculator-fields-header {
  display: inline-block;
  width: 80%;
  margin: 10px 22px;
}

.calculator-fields {
  display: inline-block;
  width: 80%;
  background-color: #FED130;
  border-radius: 10px;
  margin: 10px 22px;
}

.details-text {
  margin-top: 10px;
  font-size: 15px;
}

.calculator-field {
  width: 30%;
  margin: 10px 5px 10px 5px;
  min-height: 50px;
  display: inline-block;
}

.calculator-field select, .calculator-field input {
  width: 100%;
  min-height: 50px;
  border-radius: 5px;
  border: 2px solid #c1c1c1;
}

.calculator-field input {
  border-style: solid;
}

.calculator-arrow {
  max-width: 699px;
  min-height: 139px;
  background: url("/static/img/arrow.png") no-repeat;
  position: relative;
  top: -49px;
  left: -34px;
}

.calculator-arrow p {
  font-size: 30px;
  color: white;
  max-width: 609px;
  text-align: left;
  margin: 0 auto;
}

.calculator-description {
  margin-left: 22px;
}

.calculator-description p {
  text-align: left;
}

.calculator-description .title {
  font-size: 30px;
  color: #286ab0;
}

.calculator-description .content {
  font-size: 15px;
  color: #4f4f4f;
}

.chart {
  width: 100%;
}

@media screen and (max-device-width: 768px) {
  #calculator-step-one {
    background-color: #f0f0f0;
    width: 85%;
    margin: 50px auto 0 auto;
    padding-bottom: 50px;
    border-radius: 5px;
  }

  .chart {
    width: auto;
  }

  .multipliers input {
    margin-left: 0;
  }

  .no-mobile {
    display: none;
  }
  
  .results-info {
    flex-direction: column;
    align-items: center;
  }

  .result-actions {
    text-align: center;
  }

  #result-table {
    margin-top: 0px;
  }

  .back-button {
    margin-left: 0px
  }

  .calculator-description {
    width: 70%;
    margin: 10px auto;
  }

  .calculator-description .title, .calculator-description .content, .calculator-description p {
    text-align: center;
  }

  .on-grid-info, .off-grid-info {
    position: inherit;
    left: 0;
  }

  .grid-info {
    position: inherit;
    display: block;
    width: 95%;
  }

  .results-info {
    position: inherit;
    flex-direction: column;
  }

  .multipliers {
    width: 50%;
    min-height: 140px;
    position: inherit;
    display: block;
    margin: 0 auto;
  }

  .multipliers-input {
    width: 100%;
  }

  .contact-info {
    position: inherit;
    margin-bottom: 10px;
  }

  #chart-title {
    position: inherit;
    display: block;
    width: 90%;
    margin: 0 auto;
    height: auto;
  }

  #chart-title p {
    position: inherit;
    text-align: center;
  }

  .result-chart {
    width: 200px !important;
    height: 200px !important;
    left: 0;
    display: inline-block;
  }

  .contact-field > input {
    max-width: 150px;
  }
}

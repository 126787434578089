.calculator-description p {
  text-align: left;
}

select {
  font-size: 16px;
}

.calculator-description .title {
  font-size: 30px;
  color: #286ab0;
}

.calculator-description .content {
  font-size: 15px;
  color: #4f4f4f;
}

input {
  box-sizing: border-box
} 

.calculator-description {
  margin-left: 22px;
  margin-top: -69px;  
}

.hidden {
  display: none;
}

.customInput {
  width: 100%;
}

.remove-button {
  background-image: url("../../../assets/remove-ico.png");
  background-repeat: no-repeat;
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.calculator-fields-header {
  display: inline-block;
  width: 80%;
  margin: 10px 22px;
}

.calculator-field {
  width: 30%;
  margin: 10px 5px 10px 5px;
  min-height: 50px;
  display: inline-block;
}

.calculator-field select, .calculator-field input {
  width: 100%;
  min-height: 50px;
  border-radius: 5px;
  border: 2px solid #c1c1c1;
}

.calculator-field input {
  border-style: solid;
}

.calculator-fields {
  display: inline-block;
  width: 80%;
  background-color: #FED130;
  border-radius: 10px;
  margin: 10px 22px;
}

.mobile-remove {
  display: none;
}

.calculator-field.first-row {
  margin-left: 26px;
}

.add-item-btn {
  margin-bottom: 10px;
  cursor: hand;
}

span.glyphicon label {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  color: #2c3e50;
}

.add-icon {
  height: 14px;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

@media screen and (max-device-width: 768px) {
  .calculator-field.first-row {
      margin-left: 0;
  }

  .desktop-remove {
    display: none;
  }

  .mobile-remove {
      display: inline-block;
  }

  .desktop-remove {
      display: none;
  }

  #calculator-step-one {
      background-color: #f0f0f0;
      width: 85%;
      margin: 50px auto 0 auto;
      padding-bottom: 50px;
      border-radius: 5px;
  }

  .calculator-fields {
      display: block;
      margin: 15px auto;
      width: 85%;
      padding: 10px 20px 10px 20px;
  }

  .calculator-field {
      display: block;
      width: 100%;
      margin-left: 0;
  }
}
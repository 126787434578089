.footer-top, .footer-bottom {
  background-color: #286ab0;
  padding: 30px 0 30px 0;
}

.footer-top p {
  color: white;
  font-size: 27px;
}

.footer-top p, .footer-bottom p {
  width: 39.3333%;
  margin: 0 auto;
}

.footer-bottom p {
  font-size: 17px;
  color: white;
}

a.contact-us {
  font-size: 30px;
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

a {
  text-decoration: none;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

@media screen and (max-device-width: 768px) {
  .footer-top p, .footer-bottom p {
    width: 90%;
  }

  .btn {
    width: 90%;
    margin-bottom: 5px;
  }
}
.home {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

body, p {
  margin: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
}

@-moz-document url-prefix() {
  select {
      padding: 14px 0 0 0;
  }
}
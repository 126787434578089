.calculator-description p {
  text-align: left;
}

.calculator-description {
  margin-left: 22px;
  margin-top: -69px;
}

.calculator-description .title {
  font-size: 30px;
  color: #286ab0;
}

.calculator-description .content {
  font-size: 15px;
  color: #4f4f4f;
}

@media screen and (max-device-width: 768px) {
  .calculator-description .title, .calculator-description .content, .calculator-description p {
    text-align: center;
  }
  
  .calculator-description {
    width: 95%;
    margin: 0 auto;
  }
}
